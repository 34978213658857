import { createContext, useContext } from 'react';
import { IGlobalContent } from '../../interfaces';
import { CountryCode } from '../../lib/constants';

export const GlobalContext = createContext<IGlobalContent>({
  props: {
    cityMaster: [],
    countryMaster: [],
    countryCode: CountryCode.INR,
    pageURL: "",
    isMobileView: false,
    isHideFloatingWidget: false,
    globalProps: null,
    pageProps: { pageGone: false },
    addToWishlist: () => null,
    wishlistItems: [{ type: "", prodId: 0 }],
    isDubaiPage: false,
    addToCart: () => null,
    isUserLoggedIn:false,
    setLoading:() => null,
    isAppView:false
  },
  funs: {
    toggleFloatingWidget: (isHide: boolean) => { }
  }
})
export const useGlobalContext = () => useContext(GlobalContext)