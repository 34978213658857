import { IPageMenu } from "@/interfaces";
import Head from "next/head";

type props = {
    data?: any,
    navSchemaData: IPageMenu[]
}
const addJsonSchema = (text: string, path: string) => {
    return {
        __html: `{
        "@context": "https://schema.org",
        "@type":"SiteNavigationElement",
        "URL": "${path}",
        "Name": "${text}"
      }`
    }
}
export function SchemaHead({ data=[], navSchemaData=[] }: props) {

    const processMenu = (menu: { pageUrl: string; pageName: string; child: any[]; }, data: { __html: string; }[]) => {
        // If the menu has a valid pageUrl, add it to the data array
        if (menu.pageUrl) {
            data.push(addJsonSchema(menu.pageName, menu.pageUrl));
        }
        // If the menu has children, recursively process them
        if (menu.child && Array.isArray(menu.child) && menu.child.length > 0) {
            menu.child.forEach((childMenu) => processMenu(childMenu, data));
        }
    };
    if (Array.isArray(navSchemaData) && navSchemaData.length > 0) {
        navSchemaData.forEach((menu) => processMenu(menu, data));
    }
    if (!data)
        return null;

    return (
        <Head>
            {
           Array.isArray(data) ? data.length > 0 && data.map((item,index)=> <script type="application/ld+json" key={index} dangerouslySetInnerHTML={item} />) :
           <script type="application/ld+json" dangerouslySetInnerHTML={data} />
        }
        </Head>
    )
}